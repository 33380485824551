import { styled } from '@stitches/react';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { FormState, UseFormRegister } from 'react-hook-form';

import { useTranslations } from '../../../context/init-data.context';

interface ContactInfoProps {
  register: UseFormRegister<any>;
  formState: FormState<any>;
  setValue: (fieldName: any, value: string) => void;
}

function ContactInfo({ register, formState, setValue }: ContactInfoProps) {
  const {
    checkoutLabels: { email, phoneNumber, contactInformation },
  } = useTranslations();

  return (
    <BookClubFormFieldSet>
      <Heading tag="h3" size="s">
        {contactInformation}
      </Heading>
      <div>
        <InputReactForm
          title={email}
          placeholder={email}
          error={!!formState?.errors.email}
          errorText={formState?.errors.email?.message as string}
          type="text"
          name="email"
          register={register}
          required={true}
          autoComplete="email"
          onChange={(e) => setValue('email', e.target.value)}
        />

        <InputReactForm
          title={phoneNumber}
          placeholder={phoneNumber}
          error={!!formState?.errors.phoneNumber}
          errorText={formState?.errors.phoneNumber?.message as string}
          type="tel"
          name="phoneNumber"
          register={register}
          required={true}
          autoComplete="tel-national"
          onChange={(e) => setValue('phoneNumber', e.target.value)}
        />
      </div>
    </BookClubFormFieldSet>
  );
}

export const BookClubFormFieldSet = styled('fieldset', {
  borderBottom: '1px solid $staticBorderDefault',
  padding: '0 0 $s250 0',
  marginBottom: '$s250',
  '& div': {
    marginBottom: 0,
  },
  '& > div': {
    display: 'flex',
    gap: '12px',
    marginTop: '$s100',
    '@bpMax720': {
      flexDirection: 'column',
    },
  },
});

export default ContactInfo;
