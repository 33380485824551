import { styled } from '@stitches/react';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { FormState, UseFormRegister } from 'react-hook-form';
import { BookClubFormFieldSet } from './ContactInfo';

import { useTranslations } from '../../../context/init-data.context';

interface DeliveryInfoProps {
  register: UseFormRegister<any>;
  formState: FormState<any>;
  setValue: (fieldName: any, value: string) => void;
  billingSameAsShipping: boolean;
  handleSetBillingsSameAsShipping: (value: boolean) => void;
}

function DeliveryInfo({
  register,
  formState,
  setValue,
  billingSameAsShipping,
  handleSetBillingsSameAsShipping,
}: DeliveryInfoProps) {
  const {
    checkoutLabels: {
      firstName,
      lastName,
      deliveryAddress,
      billingAddress,
      billingAddressSameAsDeliveryAddress,
      address,
      zipCode,
      city,
    },
  } = useTranslations();

  return (
    <BookClubFormFieldSet>
      <Heading tag="h3" size="s">
        {deliveryAddress}
      </Heading>
      <div>
        <InputReactForm
          title={firstName}
          placeholder={firstName}
          error={!!formState?.errors.shipping_firstName}
          errorText={formState?.errors.shipping_firstName?.message as string}
          type="text"
          name="shipping_firstName"
          register={register}
          required={true}
          autoComplete="given-name"
          onChange={(e) => setValue('shipping_firstName', e.target.value)}
        />

        <InputReactForm
          title={lastName}
          placeholder={lastName}
          error={!!formState?.errors.shipping_lastName}
          errorText={formState?.errors.shipping_lastName?.message as string}
          type="text"
          name="shipping_lastName"
          register={register}
          required={true}
          autoComplete="family-name"
          onChange={(e) => setValue('shipping_lastName', e.target.value)}
        />
      </div>
      <AddressRow>
        <InputReactForm
          title={address}
          placeholder={address}
          error={!!formState?.errors.shipping_address}
          errorText={formState?.errors.shipping_address?.message as string}
          type="text"
          name="shipping_address"
          register={register}
          required={true}
          autoComplete="shipping street-address"
          onChange={(e) => setValue('shipping_address', e.target.value)}
        />

        <InputReactForm
          title={zipCode}
          placeholder={zipCode}
          error={!!formState?.errors.shipping_postalCode}
          errorText={formState?.errors.shipping_postalCode?.message as string}
          type="text"
          name="shipping_postalCode"
          register={register}
          required={true}
          autoComplete="shipping postal-code"
          onChange={(e) => setValue('shipping_postalCode', e.target.value)}
        />

        <InputReactForm
          title={city}
          placeholder={city}
          error={!!formState?.errors.shipping_city}
          errorText={formState?.errors.shipping_city?.message as string}
          type="text"
          name="shipping_city"
          register={register}
          required={true}
          autoComplete="shipping address-level2"
          onChange={(e) => setValue('shipping_city', e.target.value)}
        />
      </AddressRow>
      <div>
        <Checkbox
          text={billingAddressSameAsDeliveryAddress}
          isChecked={billingSameAsShipping}
          onCheck={(e) => handleSetBillingsSameAsShipping(e.target.checked)}
          css={headingStyle}
        />
      </div>

      {!billingSameAsShipping && (
        <BillingInfo>
          <Heading tag="h3" size="xs" css={headingStyle}>
            {billingAddress}
          </Heading>
          <div>
            <InputReactForm
              title={firstName}
              placeholder={firstName}
              error={!!formState?.errors.billing_firstName}
              errorText={formState?.errors.billing_firstName?.message as string}
              type="text"
              name="billing_firstName"
              register={register}
              required={true}
              autoComplete="billing given-name"
              onChange={(e) => setValue('billing_firstName', e.target.value)}
            />

            <InputReactForm
              title={lastName}
              placeholder={lastName}
              error={!!formState?.errors.billing_lastName}
              errorText={formState?.errors.billing_lastName?.message as string}
              type="text"
              name="billing_lastName"
              register={register}
              required={true}
              autoComplete="billing family-name"
              onChange={(e) => setValue('billing_lastName', e.target.value)}
            />
          </div>
          <AddressRow>
            <InputReactForm
              title={address}
              placeholder={address}
              error={!!formState?.errors.billing_address}
              errorText={formState?.errors.billing_address?.message as string}
              type="text"
              name="billing_address"
              register={register}
              required={true}
              autoComplete="billing street-address"
              onChange={(e) => setValue('billing_address', e.target.value)}
            />

            <InputReactForm
              title={zipCode}
              placeholder={zipCode}
              error={!!formState?.errors.billing_postalCode}
              errorText={
                formState?.errors.billing_postalCode?.message as string
              }
              type="text"
              name="billing_postalCode"
              register={register}
              required={true}
              autoComplete="billing postal-code"
              onChange={(e) => setValue('billing_postalCode', e.target.value)}
            />

            <InputReactForm
              title={city}
              placeholder={city}
              error={!!formState?.errors.billing_city}
              errorText={formState?.errors.billing_city?.message as string}
              type="text"
              name="billing_city"
              register={register}
              required={true}
              autoComplete="billing address-level2"
              onChange={(e) => setValue('billing_city', e.target.value)}
            />
          </AddressRow>
        </BillingInfo>
      )}
    </BookClubFormFieldSet>
  );
}

const headingStyle = {
  fontSize: '$fontSize75',
  fontFamily: 'fontBold',
};

const BillingInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s75',

  '& > div': {
    display: 'flex',
    gap: '$s75',
    marginTop: '$s100',
    '@bpMax720': {
      flexDirection: 'column',
    },
  },
});

const AddressRow = styled('div', {
  '& div:nth-child(1)': {
    flex: 2.06,
  },
  '& div:nth-child(2)': {
    flex: 1,
  },
  '& div:nth-child(3)': {
    flex: 1,
  },
});

export default DeliveryInfo;
