import ValidationTranslations from 'Models/Translations/ValidationTranslations.interface';
import { z } from 'zod';

export const getBookClubFormSchema = (
  validationLabels: ValidationTranslations
) => {
  const {
    exceededMaxLengthFirstName,
    exceededMaxLengthLastName,
    exceededMaxLengthZipCode,
    invalidEmail,
    invalidPhoneNumber,
    fillInEmail,
    fillInFirstName,
    fillInLastName,
    fillInPhoneNumber,
    fillInCity,
    fillInZipCode,
    fillInAddress,
  } = validationLabels;

  return z.object({
    email: z
      .string()
      .min(5, { message: fillInEmail })
      .email({ message: invalidEmail }),
    phoneNumber: z
      .string()
      .min(8, { message: fillInPhoneNumber })
      .regex(/^(\+)?[0-9-\s]*$/, {
        message: invalidPhoneNumber,
      }),
    shipping_firstName: z
      .string({required_error: fillInFirstName})
      .max(40, { message: exceededMaxLengthFirstName }),
    shipping_lastName: z
      .string({required_error: fillInLastName})
      .max(40, { message: exceededMaxLengthLastName }),
    shipping_address: z.string().min(2, { message: fillInAddress }),
    shipping_postalCode: z
      .string({required_error: fillInZipCode})
      .min(4, { message: fillInZipCode })
      .max(8, { message: exceededMaxLengthZipCode }),
    shipping_city: z.string().min(2, { message: fillInCity }),
    billing_firstName: z
      .string({required_error: fillInFirstName})
      .max(40, { message: exceededMaxLengthFirstName }),
    billing_lastName: z
      .string({required_error: fillInLastName})
      .max(40, { message: exceededMaxLengthLastName }),
    billing_address: z.string().min(2, { message: fillInAddress }),
    billing_postalCode: z
      .string()
      .min(4, { message: fillInZipCode })
      .max(8, { message: exceededMaxLengthZipCode }),
    billing_city: z.string().min(2, { message: fillInCity }),
  });
};
